<template>
<div>
  <!-- <div class="img2_1"  v-lazy:background-image="require('./Wlyyfn_img/img4.png')"> -->
  <div class="img2_1">
    <div class="img-2">
      <img v-lazy="require('./Wlyyfn_img/ugNIGblH.webp')" class="img-1">
    </div>
   <div class="bannert">
      <div class="txt21">文旅项目运营赋能</div>
    <div class="word21">
       旅游资源整合，旅游目的地营销，平台及区域运营赋能能力
    </div>
   </div>

  </div>
  <div class="img2_2">
    <div class="txt22">平台及区域运营赋能</div>
    <div class="word22">
     <div class="img-3">
        <img
      v-lazy="require('./Wlyyfn_img/wy7uEssX.webp')"
       class="img2_2_1">
     </div>
      <div class="txt22_2">
       <div >
        <div class="word22_2">
          平台及区域运营赋能能力
        </div>
        <div class="mt64">
          <img v-lazy="require('./Wlyyfn_img/icon1.png')" class="img22_2">
          <span class="ml14">平台加速区域化建设落地</span>
        </div>
         <div class="mt36">
          <img v-lazy="require('./Wlyyfn_img/icon3.png')"  class="img22_2">
          <span class="ml14">根据不同区域重点建设定制化的营销场景</span>
        </div>
         <div class="mt36">
          <img v-lazy="require('./Wlyyfn_img/icon2.png')" class="img22_2">
          <span class="ml14">市场商机高程度开放</span>
        </div>
        </div>
      </div>
    </div>

  </div>
 <div class="img2_3">
   <div class="txt23">
     旅游资源整合
   </div>
   <div class="txt23_1">
     <div class="txt23_2">
       <img src="./Wlyyfn_img/img_5.png" class="img2_3_1">
       <span class="woed23">空间层次，共生，主题整合</span>
     </div>
      <div class="txt23_2 ml30">
       <img src="./Wlyyfn_img/img_4.png" class="img2_3_1">
       <span class="woed23">产品，线路，市场的整合</span>
     </div>
      <div class="txt23_2 ml30">
       <img src="./Wlyyfn_img/img_3.png" class="img2_3_1">
       <span class="woed23">产业链，保障体系的整合</span>
     </div>
   </div>
 </div>
 <div class="img2_4">
   <div class="txt24">旅游目的地营销</div>
   <div class="txt24_1">
    <div class="txt24_2">
     <div >
       <img v-lazy="require('./Wlyyfn_img/img_2.png')"  class="img2_4_1">
       <div class="word24_1">完善城市旅游产品系列</div>
     </div>
    </div>
     <div class="txt24_2 ml30">
     <div >
       <img v-lazy="require('./Wlyyfn_img/img_1.png')" class="img2_4_1">
       <div class="word24_1">塑造城市旅游形象</div>
     </div>
    </div>
     <div class="txt24_2 ml30">
     <div >
       <img v-lazy="require('./Wlyyfn_img/img_7.png')" class="img2_4_1">
       <div class="word24_1">以市场为导向，以合作营销为突破口</div>
     </div>
    </div>
   </div>
 </div>
 <div class="img2_5">
    <div class="txt23">案例展示</div>
    <div class="txt25_1">
      <!-- <img src="./Wlyyfn_img/img5.png" > -->
      <!-- <div class="img2_5_1" v-lazy:background-image="require('./Wlyyfn_img/img5.jpg')"> -->
     
      <div class="img2_5_1">
         <div class="img-5">
      <img v-lazy="require('./Wlyyfn_img/7US4Cjlz.webp')" class="img-4">
    </div>
      <div class="word25_1">苏心游</div>
      </div>
       <!-- <div class="img2_5_2 ml30" v-lazy:background-image="require('./Wlyyfn_img/img2(2).png')"> -->
       <div class="img2_5_2 ml30">
          <div class="img-5">
             <img v-lazy="require('./Wlyyfn_img/SpzleffI.webp')" class="img-4">
          </div>
       <div class="word25_1">君到苏州</div>
       </div>
        <!-- <div class="img2_5_3 ml30" v-lazy:background-image="require('./Wlyyfn_img/img2.png')"> -->
        <div class="img2_5_3 ml30">
           <div class="img-5">
              <img v-lazy="require('./Wlyyfn_img/ncvS1LxG.webp')" class="img-4">
          </div>
        <div class="word25_1">怡游镇江</div>
        </div>
      
    </div>
 </div>
 <!-- <div class="img2_6">
   <img src="./Wlyyfn_img/img.png" class="img2_6_1">
 </div> -->
  <div>
  <Header></Header>
  <Footer></Footer>

  </div>
</div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scope>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.img2_1{
  height: 600px;
  width: 100%;
  background-repeat:  no-repeat;
  background-size: 100% 100%;
}
.bannert{
   position: absolute;
    left: 361px;
    top: 230px;
}
.img-1{
   height: 600px;
  width: 100%;
}
.img-2{
  height: 600px;
  width: 100%;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 200px;
		height: 200px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 600px;
  width: 100%;
	}
}
.txt21{
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  /* margin-top: 230px;
  margin-left: 360px; */
  /* padding-top: 230px; */
  /* padding-left: 360px; */
  text-align: left;
}
.txt21{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.word21{
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  /* padding-left: 360px; */
  margin-top: 20px;
  text-align: left;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}
.word21{
   animation: sport 0.5s;
  transform: translateY(0px);
}
.img2_2{
  padding-top: 80px;
  
}
.txt22{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  
}
.word22{
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 100px;
}
.img2_2_1{
  width: 585px;
  height: 420px;
}
.img-3{
   width: 585px;
  height: 420px;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 100px;
		height: 100px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
		 width: 585px;
  height: 420px;
	}
}
.txt22_2{
padding-left: 104px;
display: flex;
align-items: center;
text-align: left;
height: 420px;
width: 615px;
border-radius: 0 0 6px 6px;
background-color: rgba(255, 255, 255, 1);
box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);

}
.word22_2{
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  margin-left: 16px;
}
.img22_2{
  width: 24px;
  height: 24px;

}
.mt64{
  margin-top: 64px;
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.ml14{
  margin-left: 14px;
  width: 375px;
  font-size: 20px;
  color: #666666;
}
.mt36{
   margin-top: 36px;
  display: flex;
  align-items: center;
  margin-left: 16px;
}
.img2_3{
  background-color: #F6F8FB;
  height: 446px;
  margin: auto;
 
}
.txt23{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  padding-top: 80px;
}
.txt23_1{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.txt23_2{
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  height: 150px;
  width: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img2_3_1{
  height: 64px;
  width: 64px;
}
.woed23{
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  margin-left: 24px;
}
.ml30{
  margin-left: 30px;
}
.txt24{
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  padding-top: 80px;
}
.txt24_1{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}
.txt24_2{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
  width: 380px;
}
.img2_4_1{
  width: 64px;
  height: 64px;
}
.word24_1{
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  letter-spacing: 0.8333333134651184px;
  margin-top: 40px;
}
.img2_5{
   background-color: #F6F8FB;
  margin: auto;
}
.txt25_1{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}
.img2_5_1{
  height: 260px;
  width: 380px;
  // background-repeat:  no-repeat;
  // background-size: 100% 100%;
  border-radius: 6px;
}
.word25_1{
  text-align: left;
   color: rgba(255, 255, 255, 1);
  font-size: 18px;
  margin-left: 16px;
  margin-top: -41px;

}
.img2_5_2{
   height: 260px;
  width: 380px;
  // background-repeat:  no-repeat;
  // background-size: 100% 100%;
}
.img2_5_3{
   height: 260px;
  width: 380px;
  //  background-repeat:  no-repeat;
  // background-size: 100% 100%;
}
.img-4{
   height: 260px;
  width: 380px;
  border-radius: 6px;
}
.img-5{
   height: 260px;
  width: 380px;
   display: flex;
    justify-content: center;
    align-items: center;
    img[lazy="loading"] {
		width: 50px;
		height: 50px;
    

	}
	// 实际图片大小
	img[lazy="loaded"] {
		 height: 260px;
  width: 380px;
	}
}
.img2_6{
  margin: auto;
}
.img2_6_1{
  width: 147px;
  height: 60px;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>